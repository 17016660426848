.font_size_hover, highlight, u {
  will-change: font-size;
  transition: font-size .25s ease-in-out;
}

.font_size_hover:hover, highlight:hover, u:hover {
  font-size: 110%;
}

.scale_hover, header div #links a, header div h1 {
  will-change: transform;
  transition: transform .25s ease-in-out;
}

.scale_hover:hover, header div #links a:hover, header div h1:hover {
  transform: scale(1.05);
}

* {
  font-display: swap;
  text-overflow: "";
  cursor: default;
  font-family: Cookie;
}

html {
  scroll-behavior: smooth;
}

page-loader {
  -webkit-backdrop-filter: blur(64px) saturate(150%);
  --animationDuration: .5s;
  z-index: 2;
  will-change: opacity;
  transition: opacity var(--animationDuration) ease-in-out;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
}

u {
  text-underline-offset: .5rem;
  font-size: 105%;
  text-decoration: underline;
}

highlight {
  -webkit-backdrop-filter: blur(4px) saturate();
  background-color: #ffafcc33;
  padding: .1rem;
  font-size: 105%;
}

#noscript {
  z-index: 2;
  box-sizing: border-box;
  padding: 1rem;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  color: #fff;
  background-color: red;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  position: fixed;
  bottom: 0;
}

body {
  scrollbar-width: none;
  margin: 0;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

header {
  -webkit-backdrop-filter: blur(52px) saturate(150%);
  z-index: 1;
  padding-top: env(safe-area-inset-top);
  will-change: opacity, backdrop-filter;
  width: 100%;
  max-height: min-content;
  transition: opacity .2s ease-in-out, -webkit-backdrop-filter .5s ease-in-out, backdrop-filter .5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

header div {
  justify-content: center;
  align-items: center;
  display: flex;
}

header div h1 {
  text-align: center;
  color: #fff;
  text-shadow: .1rem .1rem #d6004f, -.1rem -.1rem #006cd1, 0 0 4px #fff, 0 0 2px #000, 0 0 4px #bde0fe;
  max-width: 100%;
  margin: 1rem 2.5rem;
  font-family: Cookie;
  font-size: 4rem;
}

@media (width <= 640px) {
  header div h1 {
    font-size: 2rem;
  }
}

header div #links {
  padding: 1.5rem;
  font-size: 2rem;
}

header div #links a {
  color: #fff;
  text-shadow: 0 0 2px #000, .05rem .05rem #ff458c;
  font-weight: bold;
  text-decoration: none;
}

@media (width <= 640px) {
  header div #links a {
    font-size: 1rem;
  }
}

.loader_hidden, .header_hidden {
  opacity: 0;
}
/*# sourceMappingURL=main.2fe5061c.css.map */
