// stecy
// Copyright (C) 2024 Oscar

@import "./consts";

* {
    font-family: "Cookie";
    font-display: swap;
    text-overflow: "";
    cursor: default;
}

html {
    scroll-behavior: smooth;
}

page-loader {
    @include glass(64px, 150%);
    --animationDuration: 500ms;
    z-index: 2;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba($color: black, $alpha: 0.5);
    will-change: opacity;
    transition: opacity var(--animationDuration) ease-in-out;
}

u {
    @extend .font_size_hover;
    font-size: 105%;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
}
highlight {
    @include glass(4px, 100%);
    @extend .font_size_hover;
    padding: 0.1rem;
    font-size: 105%;
    background-color: rgba($color: #ffafcc, $alpha: 0.2);
}

#noscript {
    z-index: 2;
    display: flex;
    position: fixed;
    justify-content: left;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
    width: 100%;
    height: auto;
    bottom: 0;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    background-color: red;
}

body {
    margin: 0;
    overflow-x: hidden;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

header {
    @include glass(52px, 150%);
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: min-content;
    padding-top: env(safe-area-inset-top);
    will-change: opacity, backdrop-filter;
    transition:
        opacity 200ms ease-in-out,
        backdrop-filter 500ms ease-in-out;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
            @extend .scale_hover;
            margin: 1rem 2.5rem 1rem 2.5rem;
            max-width: 100%;
            text-align: center;
            color: $header-title;
            text-shadow:
                0.1rem 0.1rem $header-title-shadow-1,
                -0.1rem -0.1rem $header-title-shadow-2,
                0 0 4px white,
                0 0 2px black,
                0 0 4px $header-title-bloom;
            font-size: 4rem;
            font-family: "Cookie";
            @media (max-width: 640px) {
                font-size: 2rem;
            }
        }
        #links {
            padding: 1.5rem;
            font-size: 2rem;
            a {
                @extend .scale_hover;
                text-decoration: none;
                color: $header-links;
                text-shadow:
                    0 0 2px black,
                    0.05rem 0.05rem $header-links-shadow;
                font-weight: bold;
                @media (max-width: 640px) {
                    font-size: 1rem;
                }
            }
        }
    }
}

.loader_hidden {
    opacity: 0;
}

.header_hidden {
    opacity: 0;
}
